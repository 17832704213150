<template>
  <div class="tw-relative tw-w-full">
    <NuxtImg v-if="coverPhoto && !useLibraryImage" :src="coverPhoto" :alt="title" class="tw-w-full" />
    <library-image
      v-if="coverPhoto && useLibraryImage"
      :src="coverPhoto"
      :design-height="designHeight"
      :design-width="designWidth"
      :blur="blur"
    />

    <iframe
      v-else-if="!coverPhoto && !libraryIframe"
      :class="customClasses ? customClasses : 'video tw-h-[320px] md:tw-h-[460px] lg:tw-h-[540px]'"
      width="100%"
      :src="processedVideoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>

    <library-iframe v-else-if="libraryIframe" :src="processedVideoUrl" :w-ratio="wRatio" :h-ratio="hRatio" />

    <div v-if="isPopup" class="tw-absolute tw-top-0 tw-z-10 tw-h-full tw-w-full" @click="isVisible = true">
      <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
        <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
          <circle cx="81" cy="81" r="81" fill="white" />
          <polygon points="65,45 115,81 65,117" fill="black" />
        </svg>
      </button>
    </div>
    <library-popups-video-popup v-if="isPopup" :video-url="processedVideoUrl" v-model:is-visible="isVisible" />
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'LibraryVideoEmbed',

  props: {
    videoUrl: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
    },
    height: {
      required: false,
      type: Number,
      default: 460,
    },
    isPopup: {
      required: false,
      type: Boolean,
      default: false,
    },
    coverPhoto: {
      required: false,
      type: [String, Boolean],
      default: false,
    },

    hRatio: {
      required: false,
      type: Number,
      default: 2,
    },
    wRatio: {
      required: false,
      type: Number,
      default: 3,
    },

    designWidth: {
      required: false,
      type: Number,
    },

    designHeight: {
      required: false,
      type: Number,
    },

    libraryIframe: {
      required: false,
      type: Boolean,
      default: false,
    },

    customClasses: {
      required: false,
      type: String,
      default: '',
    },

    blur: {
      required: false,
      type: String,
      default: null,
    },

    useLibraryImage: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    processedVideoUrl() {
      if (this.videoUrl.includes('youtube.com/shorts/')) {
        // Convert Shorts URL to embed URL
        return this.videoUrl.replace('youtube.com/shorts/', 'youtube.com/embed/');
      }
      return this.videoUrl;
    },
  },
});
</script>
