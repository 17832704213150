<template>
  <section class="tw-flex tw-justify-center" style="padding-top: 120px; padding-bottom: 50px">
    <library-login :is-register-button-visible="false">
      <template #title>
        <h1>Login</h1>
      </template>
      <template #explanation-text>
        <span> Welcome to the login page. </span>
      </template>

      <template #email-input-explanation-text>
        <div>
          If you think you are already registered, can't see your heads up alerts and properties enter your email
          address here and we will send you a magic link:
        </div>
      </template>
      <template #success-message>
        Thank you for submitting your request. If you have registered with us, you will receive an email. If you do not
        receive the email, please register to create heads up alerts and see exclusive properties.
        <div class="tw-mt-4 tw-flex tw-w-1/6">
          <library-button label="Register" @did-click="goRegister()" />
        </div>
      </template>
    </library-login>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { ROUTES } from '~/constants/ui.constants';
import LibraryButton from '~/components/common/Button.vue';

export default defineNuxtComponent({
  name: 'LibraryLoginBox',
  components: { LibraryButton },
  mixins: [ComponentMixin],

  methods: {
    goRegister() {
      this.redirectTo(ROUTES.REGISTER);
    },
  },
});
</script>
