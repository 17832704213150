<template>
  <div class="tw-flex tw-h-full tw-flex-col" style="font-family: var(--default-font)">
    <div v-if="label" class="tw-mb-1">
      <lazy-library-text :text="label" tag="span" type="input-label"></lazy-library-text>
      <span v-if="isRequired" style="color: #ff0000; font-size: 14px">*</span>
    </div>
    <div>
      <div class="tw-flex tw-w-full tw-px-0">
        <label id="images2" :for="'image'" class="mouse-click tw-w-full">
          <div class="upload-bg tw-flex tw-w-full tw-cursor-pointer tw-justify-center tw-p-5">
            <div class="tw-flex tw-flex-col tw-justify-center">
              <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="40"
                  height="40"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#000000"
                    d="M17.5 19h-16c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h16c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v2c0 0.827-0.673 1.5-1.5 1.5z"
                  ></path>
                  <path
                    fill="#000000"
                    d="M14.854 8.646l-5-5c-0.195-0.195-0.512-0.195-0.707 0l-5 5c-0.195 0.195-0.195 0.512 0 0.707s0.512 0.195 0.707 0l4.146-4.146v10.293c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-10.293l4.146 4.146c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707z"
                  ></path>
                </svg>
                <span class="upload-text bold mt-2">Browse your files</span>
              </div>
            </div>
          </div>
        </label>
        <input
          :id="'image'"
          ref="file"
          :key="uploaderKey"
          type="file"
          multiple
          name="post-image"
          class="ls-inputfile"
          :disabled="isLoading"
          accept="image/*"
          @change="didFileSelect"
        />
      </div>
    </div>
    <div>
      <div class="tw-mt-3 tw-grid tw-grid-cols-12 tw-gap-x-3 tw-gap-y-2">
        <div v-for="(item, key) in photos" :key="key" class="uploaded-image tw-col-span-6 lg:tw-col-span-3">
          <img :src="item" class="tw-rounded-lg" alt="file" />
          <span class="close-icon" @click="removeImage(key)">
            <i class="nc-icon nc-icon-times-circle"></i>
          </span>
        </div>
        <div v-if="isLoading" class="tw-col-span-6 tw-flex tw-items-center tw-justify-center lg:tw-col-span-3">
          <div>
            <common-loader :is-active="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ImageResizer, produceKey } from '~/helpers';
import { useFormsStore } from '~/units/forms/store';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'FormPhotos',
  components: {},
  props: {
    formUniqueId: {
      required: true,
      type: String,
    },

    isRequired: {
      required: true,
      default: false,
      type: Boolean,
    },

    label: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      photos: [],
      isLoading: false,
      uploaderKey: produceKey(),
    };
  },

  emits: ['did-photos-change'],

  computed: {
    companyUniqueId() {
      const websiteStore = useWebsiteStore();
      return websiteStore.company?.ls_company.unique_id;
    },
  },
  methods: {
    didPhotosChange() {
      this.$emit('did-photos-change', this.photos);
      this.uploaderKey = produceKey();
    },

    async uploadSdvPhotos(formData) {
      const formsStore = useFormsStore();
      const result = await formsStore.uploadSDVPhoto(formData);

      this.photos.push(result.url);
      this.isLoading = false;
      this.didPhotosChange();
    },

    async didFileSelect() {
      const images = [...this.$refs.file.files];
      for (const file of images) {
        const compressedFile = await ImageResizer.resizeImage({ file, maxSize: 1200 });
        const formData = new FormData();
        formData.append('file', compressedFile.file);
        formData.append('path', `${this.companyUniqueId}/${this.formUniqueId}/online-valuation-form-images`);
        this.isLoading = true;
        await this.uploadSdvPhotos(formData);
      }
    },
    removeImage(index) {
      this.photos.splice(index, 1);
      this.didPhotosChange();
    },
  },
});
</script>

<style scoped>
.upload-bg {
  background-color: var(--secondary-bg-color);
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  -webkit-box-shadow: 12px 10px 45px rgb(217 221 225 / 49%);
  box-shadow: 12px 10px 45px rgb(217 221 225 / 49%);
}
.ls-inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.ls-inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.ls-inputfile + label * {
  pointer-events: none;
}
.image {
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 12px 10px 45px rgba(217, 221, 225, 0.49);
}

.close-icon {
  position: absolute;
  right: 22px;
  top: 10px;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}

.uploaded-image {
  position: relative;
}
</style>
