<template>
  <!-- Variant 1 -->
  <div
    v-if="variant === 'v1'"
    data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
    class="nav-left-arrow w-nav-arrow-left !tw-z-20"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
    :isDisabled="!canPaginateBack"
    @click="() => swiper.slidePrev()"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
        fill="#000"
      />
    </svg>
  </div>
  <div
    v-if="variant === 'v1'"
    class="nav-right-arrow w-nav-arrow-right !tw-z-20"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
    :isDisabled="!canPaginateNext"
    @click="() => swiper.slideNext()"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
        fill="#000"
      />
    </svg>
  </div>

  <!-- Variant 2 -->
  <div class="controls tw-mt-8" v-if="variant === 'v2'" :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }">
    <div
      class="slide-left-arrow w-slider-arrow-left !tw-z-20"
      :isDisabled="!canPaginateBack"
      @click="() => swiper.slidePrev()"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
          fill="#6EADAB"
        />
      </svg>
      <div class="slide-circle slider-circle-transform"></div>
    </div>
    <div
      data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
      class="slide-right-arrow w-slider-arrow-right !tw-z-20"
      :isDisabled="!canPaginateNext"
      @click="() => swiper.slideNext()"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
          fill="#6EADAB"
        />
      </svg>
      <div class="slide-circle right"></div>
    </div>
  </div>

  <!-- Variant 3 -->
  <div
    v-if="variant === 'v3'"
    data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
    class="v3-left-arrow !tw-z-20"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
    :isDisabled="!canPaginateBack"
    @click="() => swiper.slidePrev()"
  >
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.707 2.29303C6.51947 2.10556 6.26516 2.00024 6 2.00024C5.73484 2.00024 5.48053 2.10556 5.293 2.29303L0.793 6.79303C0.605529 6.98056 0.500214 7.23487 0.500214 7.50003C0.500214 7.76519 0.605529 8.0195 0.793 8.20703L5.293 12.707C5.4816 12.8892 5.7342 12.99 5.9964 12.9877C6.2586 12.9854 6.50941 12.8803 6.69482 12.6948C6.88023 12.5094 6.9854 12.2586 6.98767 11.9964C6.98995 11.7342 6.88916 11.4816 6.707 11.293L4 8.50003H13.5C13.7652 8.50003 14.0196 8.39467 14.2071 8.20714C14.3946 8.0196 14.5 7.76525 14.5 7.50003C14.5 7.23481 14.3946 6.98046 14.2071 6.79292C14.0196 6.60539 13.7652 6.50003 13.5 6.50003H4L6.707 3.70703C6.89447 3.5195 6.99979 3.26519 6.99979 3.00003C6.99979 2.73487 6.89447 2.48056 6.707 2.29303Z"
        fill="black"
      />
    </svg>
  </div>
  <div
    v-if="variant === 'v3'"
    class="v3-right-arrow !tw-z-20"
    :isDisabled="!canPaginateNext"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
    @click="() => swiper.slideNext()"
  >
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.293 2.29303C8.48053 2.10556 8.73484 2.00024 9 2.00024C9.26516 2.00024 9.51947 2.10556 9.707 2.29303L14.207 6.79303C14.3945 6.98056 14.4998 7.23487 14.4998 7.50003C14.4998 7.76519 14.3945 8.0195 14.207 8.20703L9.707 12.707C9.5184 12.8892 9.2658 12.99 9.0036 12.9877C8.7414 12.9854 8.49059 12.8803 8.30518 12.6948C8.11977 12.5094 8.0146 12.2586 8.01233 11.9964C8.01005 11.7342 8.11084 11.4816 8.293 11.293L11 8.50003H1.5C1.23478 8.50003 0.98043 8.39467 0.792893 8.20714C0.605357 8.0196 0.5 7.76525 0.5 7.50003C0.5 7.23481 0.605357 6.98046 0.792893 6.79292C0.98043 6.60539 1.23478 6.50003 1.5 6.50003H11L8.293 3.70703C8.10553 3.5195 8.00021 3.26519 8.00021 3.00003C8.00021 2.73487 8.10553 2.48056 8.293 2.29303Z"
        fill="black"
      />
    </svg>
  </div>

  <!-- Variant 4 -->
  <div class="v4 tw-mt-6" v-if="variant === 'v4'" :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }">
    <div class="v4-slide-left !tw-z-20" :isDisabled="!canPaginateBack" @click="() => swiper.slidePrev()">
      <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.5 5.25H17.25V6.75H16.5V5.25ZM0.96967 6.53033C0.676777 6.23744 0.676777 5.76256 0.96967 5.46967L5.74264 0.696699C6.03553 0.403806 6.51041 0.403806 6.8033 0.696699C7.09619 0.989593 7.09619 1.46447 6.8033 1.75736L2.56066 6L6.8033 10.2426C7.09619 10.5355 7.09619 11.0104 6.8033 11.3033C6.51041 11.5962 6.03553 11.5962 5.74264 11.3033L0.96967 6.53033ZM16.5 6.75H1.5V5.25H16.5V6.75Z"
          fill="var(--c-text-primary)"
        />
      </svg>
    </div>
    <div
      data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
      class="v4-slide-right !tw-z-20"
      :isDisabled="!canPaginateNext"
      @click="() => swiper.slideNext()"
    >
      <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.5 5.25H0.75V6.75H1.5V5.25ZM17.0303 6.53033C17.3232 6.23744 17.3232 5.76256 17.0303 5.46967L12.2574 0.696699C11.9645 0.403806 11.4896 0.403806 11.1967 0.696699C10.9038 0.989593 10.9038 1.46447 11.1967 1.75736L15.4393 6L11.1967 10.2426C10.9038 10.5355 10.9038 11.0104 11.1967 11.3033C11.4896 11.5962 11.9645 11.5962 12.2574 11.3033L17.0303 6.53033ZM1.5 6.75H16.5V5.25H1.5V6.75Z"
          fill="var(--c-text-primary)"
        />
      </svg>
    </div>
  </div>

  <!-- Variant 5 -->
  <div class="v5" v-if="variant === 'v5'" :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }">
    <div class="v5-slide-left !tw-z-20" :isDisabled="!canPaginateBack" @click="() => swiper.slidePrev()">
      <svg width="28" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31 5.25H31.75V6.75H31V5.25ZM0.469669 6.53033C0.176777 6.23744 0.176777 5.76256 0.469669 5.46967L5.24264 0.696699C5.53553 0.403806 6.01041 0.403806 6.3033 0.696699C6.59619 0.989593 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53553 11.5962 5.24264 11.3033L0.469669 6.53033ZM31 6.75H1V5.25H31V6.75Z"
          fill="var(--c-text-primary)"
        />
      </svg>
    </div>
    <div
      data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
      class="v5-slide-right !tw-z-20"
      :isDisabled="!canPaginateNext"
      @click="() => swiper.slideNext()"
    >
      <svg width="28" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 5.25H0.25V6.75H1V5.25ZM31.5303 6.53033C31.8232 6.23744 31.8232 5.76256 31.5303 5.46967L26.7574 0.696699C26.4645 0.403806 25.9896 0.403806 25.6967 0.696699C25.4038 0.989593 25.4038 1.46447 25.6967 1.75736L29.9393 6L25.6967 10.2426C25.4038 10.5355 25.4038 11.0104 25.6967 11.3033C25.9896 11.5962 26.4645 11.5962 26.7574 11.3033L31.5303 6.53033ZM1 6.75H31V5.25H1V6.75Z"
          fill="var(--c-text-primary)"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'LibrarySliderPagination',

  props: {
    swiper: {
      required: true,
    },

    variant: {
      type: String,
      default: 'v1',
    },

    breakpoint: {
      type: Number,
      default: 768,
    },

    canPaginateBack: {
      type: Boolean,
      default: true,
    },

    canPaginateNext: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style>
/* V1 */

.nav-left-arrow,
.nav-right-arrow {
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.nav-left-arrow {
  left: -20px;
}
.nav-right-arrow {
  right: -20px;
}

.nav-left-arrow[isDisabled='true'],
.nav-right-arrow[isDisabled='true'] {
  cursor: default;
}

.nav-left-arrow svg,
.nav-right-arrow svg {
  width: 32px;
  height: 32px;
}

.nav-left-arrow:not([isDisabled='true']):hover svg path,
.nav-right-arrow:not([isDisabled='true']):hover svg path {
  fill: var(--c-primary);
}

/* V2 */

.controls {
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  display: flex;
}

.slide-left-arrow {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.slide-right-arrow {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.slide-left-arrow,
.slide-right-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 28px;
  background-color: white;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
}

.latest-properties-sidebar .slide-left-arrow,
.latest-properties-sidebar .slide-right-arrow {
  background-color: #f7fbf3;
}

.slide-left-arrow[isDisabled='true'],
.slide-right-arrow[isDisabled='true'] {
  cursor: default;
}
.slide-left-arrow:not([isDisabled='true']):hover,
.slide-right-arrow:not([isDisabled='true']):hover {
  background-color: var(--c-primary) !important;
}

.slide-left-arrow svg,
.slide-right-arrow svg {
  width: 32px;
  height: 32px;
}

.slide-left-arrow:not([isDisabled='true']):hover svg path,
.slide-right-arrow:not([isDisabled='true']):hover svg path {
  fill: white;
}

.slide-left-arrow::after,
.slide-right-arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: #969696;
  transform: translate(-50%, -50%);
}

.slide-left-arrow::after {
  left: 100%;
}

.slide-right-arrow::after {
  left: 0;
}

/* V3 */

.v3-left-arrow,
.v3-right-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  box-shadow: var(--c-box_shadow);
}

@media screen and (min-width: 1300px) {
  .v3-left-arrow,
  .v3-right-arrow {
    padding: 16px;
  }
}

.v3-left-arrow {
  transform: translatex(-60%);
  left: 10px;
}

.v3-right-arrow {
  transform: translatex(60%);
  right: 10px;
}

.v3-left-arrow[isDisabled='true'],
.v3-right-arrow[isDisabled='true'] {
  cursor: default;
}

.v3-left-arrow:not([isDisabled='true']):hover,
.v3-right-arrow:not([isDisabled='true']):hover {
  background-color: var(--c-secondary);
}

.v3-left-arrow:not([isDisabled='true']):hover svg path,
.v3-right-arrow:not([isDisabled='true']):hover svg path {
  fill: white;
}

/* V4 */
.v4 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.v4-slide-left,
.v4-slide-right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid var(--c-text-primary);
}

.v4-slide-left[isDisabled='true'],
.v4-slide-right[isDisabled='true'] {
  cursor: default;
}

.v4-slide-left:not([isDisabled='true']):hover,
.v4-slide-right:not([isDisabled='true']):hover {
  background-color: var(--c-primary);
  border-color: var(--c-primary);
}

/* V5 */
.v5 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.v5-slide-left,
.v5-slide-right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px 13px;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid var(--c-text-primary);
  width: 50px;
  height: 50px;
}

@media screen and (min-width: 1024px) {
  .v5-slide-left,
  .v5-slide-right {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 768px) {
  .v3-left-arrow {
    transform: translatex(-60%);
    left: 24px;
  }

  .v3-right-arrow {
    transform: translatex(60%);
    right: 24px;
  }
}

.v5-slide-left[isDisabled='true'],
.v5-slide-right[isDisabled='true'] {
  cursor: default;
}

.v5-slide-left:not([isDisabled='true']):hover,
.v5-slide-right:not([isDisabled='true']):hover {
  background-color: var(--c-primary);
  border-color: var(--c-primary);
}

.v5-slide-left:not([isDisabled='true']):hover svg path,
.v5-slide-right:not([isDisabled='true']):hover svg path {
  fill: var(--c-text-secondary);
}
</style>
