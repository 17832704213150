<template>
  <section
    v-if="variant === variants.v1"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-flex tw-w-full tw-max-w-2xl tw-flex-col tw-items-center tw-bg-white tw-p-10 tw-text-center md:tw-w-4/5 xl:tw-w-1/2"
      style="border-radius: var(--rounded)"
    >
      <NuxtImg v-if="vars.logoImage" :src="vars.logoImage" :width="180"></NuxtImg>
      <div v-html="vars.titleHtml"></div>
      <div class="tw-my-5">
        <p
          class="tw-flex tw-flex-col tw-gap-4"
          v-for="(item, index) in descriptions"
          :key="`content-box-description-${index}`"
        >
          {{ item.description }}
        </p>
      </div>
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-2">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`card-btn-${index}`"
          class="n-fineandcountry"
          target="_blank"
          :to="button.link"
          :external="true"
        >
          {{ button.label }}
        </nuxt-link>
      </div>
    </div>
  </section>

  <section
    v-if="variant === variants.v2"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-grid tw-grid-cols-1 tw-items-center tw-justify-center tw-gap-4 xl:tw-grid-cols-12 xl:tw-gap-20">
      <NuxtImg
        v-if="vars.photoImage && !vars.videoUrl"
        :src="vars.photoImage"
        :alt="'Image'"
        class="tw-w-full tw-rounded-xl tw-object-cover xl:tw-col-span-5"
      />
      <div v-if="vars.videoUrl" class="tw-w-full tw-object-cover xl:tw-col-span-5">
        <library-video-embed :video-url="vars.videoUrl" :is-popup="vars.isPopupBool" :cover-photo="vars.photoImage" />
      </div>
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-4 xl:tw-col-span-7">
        <div v-html="vars.titleHtml"></div>
        <div class="tw-my-5 tw-text-white">
          <p
            class="tw-flex tw-flex-col tw-gap-4"
            v-for="(item, index) in descriptions"
            :key="`content-box-description-${index}`"
          >
            {{ item.description }}
          </p>
        </div>
        <div class="tw-flex tw-w-full tw-gap-2 max-md:tw-flex-wrap max-md:tw-justify-center">
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="`card-btn-${index}`"
            class="n-fineandcountry tw-text-center max-md:tw-w-[inherit]"
            :to="button.link"
            :external="true"
            target="_blank"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="variant === variants.v3"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-max-w-3xl tw-flex-col tw-items-center tw-text-center">
      <NuxtImg v-if="vars.logoImage" :src="vars.logoImage" :width="220"></NuxtImg>
      <div class="tw-mb-5" v-html="vars.descriptionHtml"></div>
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-2">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`card-btn-${index}`"
          class="n-fineandcountry"
          :to="button.link"
          :external="true"
        >
          {{ button.label }}
        </nuxt-link>
      </div>
    </div>
  </section>
  <div
    v-if="static && static?.variant === variants.v3"
    class="n-section-primary tw-mb-4 tw-flex tw-items-center tw-justify-center tw-py-8"
    :style="`background-color: var(--c-${static.bgColor}); border-radius: var(--rounded);`"
  >
    <div class="tw-flex tw-max-w-3xl tw-flex-col tw-items-center tw-text-center">
      <NuxtImg v-if="static.logo" :src="static.logo" :width="220"></NuxtImg>
      <div class="tw-mb-5" v-html="static.title"></div>
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-2">
        <nuxt-link
          v-for="(button, index) in static.buttons"
          :key="`card-btn-${index}`"
          class="n-fineandcountry"
          :to="button.link"
          :external="true"
        >
          {{ button.label }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FineAndCountry',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
      },
    };
  },

  props: {
    static: {
      type: Object,
      required: false,
    },
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
