import { defineStore } from 'pinia';
import { FormsService } from '~/units/forms/services';
import type { FormPayload } from '~/units/forms/types';
import { useContactsStore } from '~/units/contacts/store';

export const useFormsStore = defineStore({
  id: 'forms',
  state: () => {
    return {};
  },
  actions: {
    async sendFormToAkya(uniqueId: string, payload: FormPayload) {
      const result = await new FormsService().send(uniqueId, payload);

      const contactsStore = useContactsStore();
      await contactsStore.updateSession(null, result.contact_id);

      return result;
    },

    async uploadSDVPhoto(payload) {
      return await new FormsService().uploadSdvPhoto(payload);
    },
  },
  getters: {},
});
