<template>
  <div
    :class="
      [FormFieldTypes.FIRST_NAME, FormFieldTypes.SURNAME].includes(field.field_type_id)
        ? 'tw-col-span-6'
        : 'tw-col-span-12'
    "
    :style="field.type === 'hidden' || field.is_hidden ? 'display: none' : ''"
  >
    <div v-if="field.is_hidden" :key="`is-hidden-${fieldIndex}`">
      <input :id="field.name" :type="field.type" :name="field.name" value="" />
    </div>
    <div v-else-if="isLongTextArea" :key="`textarea-${fieldIndex}`" class="form-field-wrapper">
      <nc-input
        v-model="childModelValue"
        :name="field.name"
        type="textarea"
        :label="field.label"
        :is-required="field.required"
        :placeholder="field.placeholder"
      >
      </nc-input>
    </div>
    <div v-else-if="field.type === 'textarea'" :key="`textarea-${fieldIndex}`" class="form-field-wrapper">
      <nc-input
        v-model="childModelValue"
        :name="field.name"
        :label="field.label"
        :is-required="field.required"
        :placeholder="field.placeholder"
      >
      </nc-input>
    </div>
    <div v-else-if="field.type === 'select'" :key="`select-${fieldIndex}`">
      <nc-select
        v-model="childModelValue"
        :label="field.label"
        :options="field.options"
        :is-required="field.required"
        :name="field.name"
        label-field="label"
      />
    </div>
    <div v-else-if="field.type === 'radio'" :key="`radio-${fieldIndex}`" class="form-field-wrapper">
      <nc-radio v-model="childModelValue" :name="field.name" :label="field.label" :options="field.options" />
    </div>
    <div v-else-if="field.type === 'text'" :key="`field-${fieldIndex}`" class="form-field-wrapper">
      <nc-input
        v-model="childModelValue"
        :type="field.type"
        :name="field.name"
        :label="field.label"
        :placeholder="field.placeholder || field.label"
        :is-required="field.required"
      ></nc-input>
    </div>
    <div v-else-if="field.type === 'number'" :key="`field-${fieldIndex}`" class="form-field-wrapper">
      <nc-input
        v-model="childModelValue"
        :type="field.type"
        :name="field.name"
        :label="field.label"
        :placeholder="field.placeholder || field.label"
        :is-required="field.required"
      ></nc-input>
    </div>
    <div v-else-if="field.type === 'email'" :key="`email-${fieldIndex}`" class="form-field-wrapper">
      <nc-input
        v-model="childModelValue"
        type="email"
        :label="field.label"
        :name="field.name"
        :placeholder="field.placeholder || field.label"
        :is-required="field.required"
      ></nc-input>
    </div>
    <div v-else-if="field.type === 'tel'" :key="`tel-${fieldIndex}`" class="form-field-wrapper">
      <nc-input
        v-model="childModelValue"
        type="tel"
        :name="field.name"
        :label="field.label"
        :placeholder="field.placeholder || field.label"
        :is-required="field.required"
      ></nc-input>
    </div>
    <div v-else-if="field.type === 'checkbox-group'" :key="`checkbox-group-${fieldIndex}`" class="d-flex flex-column">
      <nc-checkbox
        v-model="childModelValue"
        mode="multiple"
        :label="field.label"
        :multiple-checkbox-label="field.label"
        :name="field.name"
        :is-required="field.required"
        :multiple-checkbox-list="field.options"
      />
    </div>
    <div v-else-if="field.type === 'checkbox'" :key="`checkbox-${fieldIndex}`" class="d-flex flex-column">
      <nc-checkbox
        v-model="childModelValue"
        mode="multiple"
        :multiple-checkbox-list="field.options"
        :multiple-checkbox-label="field.label"
        :name="field.name"
        :label="field.label"
        :is-required="field.required"
      />
    </div>
    <div v-else-if="field.type === 'hidden'" :key="`hidden-${fieldIndex}`">
      <input :id="field.name" :type="field.type" :name="field.name" value="" />
    </div>
    <div v-else-if="field.field_type_id === FormFieldTypes.DATE">
      <library-date-picker :name="field.name" v-model="field.value" :is-required="field.required" :label="field.label">
      </library-date-picker>
    </div>
    <div v-else-if="field.field_type_id === FormFieldTypes.ADDRESS">
      <!-- Dynamic placeholder -->
      <!-- description?? -->
      <postcode-searcher
        v-model="childModelValue"
        :field-name="field.name"
        :is-address-mode-switching-enabled="false"
        :search-input-label="field.label"
        search-input-placeholder="Search by postcode"
        :is-required="field.required"
      />
    </div>
    <div v-else-if="field.field_type_id === FormFieldTypes.FILE">
      <library-form-photos
        :form-unique-id="formUniqueId"
        :is-required="field.required"
        :label="field.label"
        @did-photos-change="didPhotosChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import NcCheckbox from '~/components/common/inputs/NcCheckbox.vue';
import NcInput from '~/components/common/inputs/NcInput.vue';
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import NcRadio from '~/components/common/inputs/NcRadio.vue';
import PostcodeSearcher from '~/components/common/address/PostcodeSearcher.vue';
import { FormFieldTypes } from '~/units/forms/types';

interface CheckboxOption {
  value: string;
  label: string;
  required: boolean;
}

export interface FormFieldItem {
  label: string;
  type: 'text' | 'hidden' | 'tel' | 'email' | 'checkbox-group' | 'checkbox' | 'textarea' | 'select' | 'radio';
  name: string;
  value: string | boolean | string[] | null;
  required: boolean;
  placeholder?: string;
  options?: Array<CheckboxOption>;
  field_type_id: FormFieldTypes;
  is_hidden: boolean;
  unique_id: string;
}

export default defineNuxtComponent({
  name: 'FormField',
  components: { PostcodeSearcher, NcRadio, NcSelect, NcInput, NcCheckbox },

  props: {
    formUniqueId: {
      required: true,
      type: String,
    },

    modelValue: {
      required: true,
    },

    field: {
      required: true,
      type: Object as PropType<FormFieldItem>,
    },

    fieldIndex: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      fullAddress: null,
    };
  },

  computed: {
    FormFieldTypes() {
      return FormFieldTypes;
    },

    childModelValue: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },

    isLongTextArea() {
      return this.field.type === 'textarea' && this.field.label.toString().toLowerCase().includes('message');
    },
  },

  methods: {
    didPhotosChange(photos) {
      this.childModelValue = photos;
    },
  },
});
</script>

<style scoped></style>
